import "current-script-polyfill";
import Vue from "vue";
import Router from'vue-router';
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);

Vue.use(require("vue-moment"));

Vue.config.productionTip = false;

Vue.use(Router);
const router= new Router({
    routes:[
      {
        path:'/:id',
        name:'post',
        props:true,
      },
    ]
  })


new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount("#app");
