<template>
  <v-app>
    <div style="padding-left:5vw; padding-right:5vw" v-if="loaded">
      <v-row>
          <v-col cols="12">
            <center>
            <img
              :src="logoURL"
              width="100px"
              style="padding-top:2vh; float:left"
            />
              <h2 :style="getFontBold" v-html="'<br>' + name"></h2>
            </center>
          </v-col>
      </v-row>
    </div>
    <center v-if="!formSubmitted && !closed">
      <h3 :style="getFontRegular">
        <br />Please fill out your details in the form below to confirm your attendance.<br />
      </h3>
    </center>
    <center v-if="!activeForm">
      <h3 :style="getFontRegular">
        <br />Sorry we have not been able to find that form, please check the URL<br />
      </h3>
    </center>
    <center v-if="closed && activeForm">
      <h3 :style="getFontRegular" v-if="loaded">
        <br />The {{name}} site has been closed<br />
      </h3>
    </center>
    <v-main transition="slide-x-transition">
      <v-container v-if="formSubmitted">
        <v-row>
          <v-col cols="12">
            <center>
              <h3 :style="getFontRegular">
                <br /><br />Thank you for registering, we look forward to seeing you.<br/><br/> You may now close the page.
              </h3>
            </center>
          </v-col>
        </v-row>
      </v-container>
      <v-form v-model="valid" v-if="!formSubmitted && !closed">
        <v-container>
          <v-row>
            <v-col cols="12">
                <center><b><p v-html="privacy_data"></p></b></center>
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="fullname"
                v-model="fullname"
                :style="getFontRegular"
                :rules="nameRules"
                label="NAME *"
                required
                v-on:keyup.enter="$refs.publication.focus()"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="publication"
                v-model="publication"
                :style="getFontRegular"
                label="PUBLICATION / COMPANY*"
                :rules="publicationRules"
                v-on:keyup.enter="$refs.email.focus()"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="email"
                v-model="email"
                :style="getFontRegular"
                :rules="emailRules"
                label="EMAIL ADDRESS *"
                required
                v-on:keyup.enter="$refs.mobile.focus()"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                ref="mobile"
                v-model="mobile"
                :style="getFontRegular"
                :rules="mobileRules"
                label="MOBILE NUMBER *"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="9" v-if="dateNeeded">
              <v-menu 
                ref="field1RefMenu"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="date_attending"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="date_attending"                  
                    id="date_attending"                  
                    v-model="date_attending"
                    label="DATE ATTENDING *"
                    :style="getFontRegular"
                    prepend-icon="mdi-calendar"
                    :rules="[allowedDates]"
                    style="padding-left:10px"                    
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_attending"
                  no-title                  
                  :min="this.event_start.format('YYYY-MM-DD')"
                  :max="this.event_end.format('YYYY-MM-DD')"
                  scrollable
                  :picker-date.sync="show_start_date"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.field1RefMenu.save(date_attending)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            
            <v-col cols="3">
              <v-checkbox label="CANNOT ATTEND" v-model="not_attending" @change="notattendingcheck()" :style="getFontRegular">CANNOT ATTEND</v-checkbox>
            </v-col>

            <v-col cols="12" v-if="!not_attending">
              <v-checkbox label="DO YOU HAVE AN ELECTRIC VEHICLE THAT NEEDS CHARGING" v-model="need_charging" :style="getFontRegular">DO YOU HAVE AN ELECTRIC VEHICLE THAT NEEDS CHARGING</v-checkbox>
            </v-col>


            
            <v-col cols="12" v-if="showSlots && !not_attending">
              <v-select
                ref="slot"
                v-model="slot"
                :style="getFontRegular"
                label="TIME SLOT" 
                :items="slotOptions"          
              ></v-select>
            </v-col>

            <v-col cols="12" v-if="!not_attending">
              <v-textarea
                ref="dietary_requirements"
                v-model="dietary_requirements"
                :style="getFontRegular"
                label="DIETARY REQUIREMENTS"
                placeholder="If you have any specific dietary requirements, please write here"
              ></v-textarea>
            </v-col>

            <v-col cols="12" v-if="!not_attending">
              <v-textarea
                ref="comments"
                v-model="comments"
                :style="getFontRegular"
                label="COMMENTS"
                placeholder="If you have any comments or need to tell us something, please write here"
              ></v-textarea>
            </v-col>




            <v-col cols="12">
              <v-alert :value="alert" prominent type="error">
                Sorry there seems to have been a problem sending your data
                please try again later
              </v-alert>
            </v-col>

            <v-col cols="12">
              <center>
                <v-btn
                  color="primary"
                  @click="submitForm()"
                  style="width:100%"
                  :style="getFontRegular"
                  :disabled="saving"
                  >{{ saving ? "SENDING DATA" : "SUBMIT" }}</v-btn
                >
              </center>
            </v-col>

            <v-col cols="12">
              <center><small><a href="https://www.bmw.co.uk/en/footer/legal/privacy-policy.html" target="_blank">Privacy Policy</a></small></center><br/><br/><br/>
            </v-col>

          </v-row>
        </v-container>
      </v-form>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import Moment from "moment";

export default {
  name: "App",
  data: () => ({
    //baseUrl: 'https://bmwdatacapture.test7.mediabasedirect.com/',
    //baseUrl: 'https://leadr.test/',
    baseUrl: 'https://bmwdatacapture.mediabasedirect.com/',
    closed: true,
    closeDateTime: "2020-09-29 14:00:00",
    event_code: "250113",
    event_brand: 'BMW',
    allowDates: [],
    name:'',
    logo:'',
    terms:'',
    show_start_date:'',
    brand:'BMW',
    privacy_data:'',
    event_start: null,
    event_end:null,

    registerID: '',

    menu1:'',
    menuLeave:'',
    slotOptions: ['1','2'],

    saving: false,
    loaded:false,    
    alert: false,
    formSubmitted: false,

    valid: false,
    validForm: true,
    activeForm: false,
    dateNeeded: false,
    showSlots: false,

    fullname: "",
    publication: "",
    email: "",
    mobile: "",
    date_attending: "",
    dietary_requirements: "",
    comments: "",
    slot:"",
    not_attending:false,
    publicationRules: [v => !!v || "Publication is required"],
    nameRules: [v => !!v || "Name is required"],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    mobileRules: [v => !!v || "Mobile is required"],
    dateAttendingRules: [v => !!v || "Date Attending is required"],

  }),

  mounted() {



    if (this.$root.$router.currentRoute.params['id']) {
      this.registerID = this.$root.$router.currentRoute.params['id'];
      this.activeForm = true;
    } else {
      this.activeForm = false;
      this.closed = true;
    }

    this.getData();



  },
  computed: {
    logoURL() {
      return require('@/assets/' + this.brand + '.png');
    },
    getFontRegular() {
      return 'font-family: "' + this.event_brand + ' Type Next Regular" !important;'
    },
    getFontBold() {
      return 'font-family: "' + this.event_brand + ' Type Next Bold" !important;'
    }
  },  
  methods: {

    allowedDates(val) {

      if (this.not_attending) {
        return true;
      }

      if (val == '') {
        return 'Date Attending is required'
      }

      var slots = [];

      this.showSlots  =false;

      var found = false;
      this.allowDates.forEach(allowDate => {
        console.log(allowDate[0],val)
        if (allowDate[0] == val) {
          found = true;
          if (!allowDate[1] || allowDate[1].length === 0 ) {
            slots = [];
          } else {
            slots = allowDate[1].split(',')
            this.showSlots  =true;
          }
        }
      });


      if (!found) 
      {
        return 'Unfortunately, this day is now fully booked. Please choose another.';
      } else {

        console.log(slots)
        this.slotOptions = slots;        

        return true;
      }
    },

    getData() {
      axios.get(this.baseUrl + '/registercapture/' + this.registerID)
          .then(
              rsp => {

                  this.closeDateTime = rsp.data.registrationForm.start_date;
                  var closeDate = Moment(this.closeDateTime);    
                  closeDate = closeDate.subtract(1,'days');

                  this.event_start = Moment(rsp.data.registrationForm.start_date);
                  this.event_end = Moment(rsp.data.registrationForm.end_date);



                  this.allowDates = rsp.data.registrationForm.allowed_dates;



                  if (this.event_start.format("YYYY-MM-DD") == this.event_end.format("YYYY-MM-DD")) {
                    this.dateNeeded = false;
                    this.date_attending = this.event_start.format("YYYY-MM-DD")
                  } else {
                    this.date_attending = rsp.data.registrationForm.customer_date;
                    if (this.date_attending == undefined) {
                      this.date_attending='';
                      this.show_start_date = this.event_start.format('YYYY-MM');
                    }
                    this.dateNeeded = true;
                  }

                  if (closeDate < Moment()) {
                    this.closed = true;
                  } else {
                    this.closed = false;
                  }     
                  
                  if (this.allowDates.length == 0) {
                    this.closed = true;
                  }
                  

                  this.name = rsp.data.registrationForm.form_name;
                  this.brand = rsp.data.registrationForm.event_brand.code;


                  this.privacy_data =  rsp.data.registrationForm.event_text;


                  this.logo = './assets/' + this.brand +'.png';


                  this.fullname = rsp.data.registrationForm.customer_name;
                  if (this.fullname == undefined) { this.fullname = '';}

                  this.publication = rsp.data.registrationForm.customer_publication;

                  console.log(this.publication , rsp.data.registrationForm.customer_publication)

                  this.email = rsp.data.registrationForm.customer_email;
                  if (this.email == undefined) { this.email = '';}
                  this.mobile = rsp.data.registrationForm.customer_mobile;
                  if (this.mobile == undefined) { this.mobile = '';}

                  this.event_code = rsp.data.registrationForm.event_code;

                  this.event_brand = rsp.data.registrationForm.event_brand.code;

                  document.title = this.event_brand + ' RSVP';
                  
                  if (this.event_brand == 'MINI') {
                    const favicon = document.getElementById("favicon");
                    favicon.href = "/mini_favicon.ico";                  
                  }


                  this.loaded = true;                      


              }
          )
    },

    notattendingcheck() {
      if (this.date_attending != '' && !this.not_attending) {
        this.date_attending.val = '';
      }
      if (this.not_attending) {
        this.date_attending = 'Not Attending';
      }

    },

    submitForm() {
      this.validForm = true;
      this.signatureInValid = false;

      console.log(this);

      if (this.fullname == "") {
        this.validForm = false;
        this.$refs.fullname.focus();
      }

      if (this.publication == "" && this.validForm) {
        this.validForm = false;
        this.$refs.publication.focus();
      }

      if (this.email == "" && this.validForm) {
        this.validForm = false;
        this.$refs.email.focus();
      }

      if (this.mobile == "" && this.validForm) {
        this.validForm = false;
        this.$refs.mobile.focus();
      }

      if (this.date_attending == "" && this.dateNeeded && this.validForm) {
        this.validForm = false;
        this.$refs.date_attending.focus();
      }

      if (this.allowedDates(this.date_attending) != true && this.dateNeeded && this.validForm) {
        this.validForm = false;
        this.$refs.date_attending.focus();
      } 

      if (this.slot == "" && this.showSlots && this.validForm) {
        this.validForm = false;
        this.$refs.slot.focus();
      } 



      if (this.validForm) {
        // form is valid lets send the form

        this.saving = true;
        this.alert = false;

        var url =
          this.baseUrl + "capture/registerdata";
         
        let fd = new FormData();
        fd.append("fullname", this.fullname.replace(/<\/?[^>]+(>|$)/g, ""));
        fd.append("publication", this.publication.replace(/<\/?[^>]+(>|$)/g, ""));
        fd.append("email", this.email.replace(/<\/?[^>]+(>|$)/g, ""));
        fd.append("mobile", this.mobile.replace(/<\/?[^>]+(>|$)/g, ""));
        if (!this.not_attending) {
          fd.append("date_attending", this.date_attending);
        }
        if (this.not_attending ) {
          fd.append("not_attending", 1);
        }
        if (this.need_charging ) {
          fd.append("need_charging", 1);
        }
        
        
        fd.append("dietary_requirements", this.dietary_requirements.replace(/<\/?[^>]+(>|$)/g, ""));
        fd.append("comments", this.comments.replace(/<\/?[^>]+(>|$)/g, ""));
        fd.append("event_code", this.event_code);
        fd.append("register_id", this.registerID);
        fd.append("slot", this.slot);

        axios
          .post(url, fd)
          .then(() => {
            this.formSubmitted = true;
            this.saving = false;
          })
          .catch(error => {
            console.log({ ...error });
            this.alert = true;
            this.saving = false;
          });
      }
    }
  }
};
</script>

<style>
@font-face {
  font-family: "BMW Type Next Bold";
  src: url("./assets/BMWTypeNextLatinTT-Bold.woff") format("woff");
}
@font-face {
  font-family: "BMW Type Next Regular";
  src: url("./assets/BMWTypeNextLatinTT-Regular.woff") format("woff");
}
@font-face {
  font-family: "BMW Type Next Regular";
  src: url("./assets/BMWTypeNextLatinTT-Light.woff") format("woff");
}

@font-face {
  font-family: "MINI Type Next Bold";
  src: url("./assets/mini_serif-bold-web.woff") format("woff");
}

@font-face {
  font-family: "MINI Type Next Regular";
  src: url("./assets/mini_serif-regular-web.woff") format("woff");
}

@font-face {
  font-family: "BMW Motorrad Type Next Bold";
  src: url("./assets/BMWMotorradW05-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "BMW Motorrad Type Next Regular";
  src: url("./assets/BMWMotorradW05-Regular.woff2") format("woff2");
}



.subTitle {
  font-family: "MOTORAD Type Next Bold" !important;
  border-bottom: 1px solid #ccc;
  font-size: 1.3em;
  display: block;
  width: 100%;
}
.v-application--wrap {
  max-width: 800px !important;
  margin-left: auto;
  margin-right: auto;
}
.v-messages__message {
  color: red;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
